import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/'
import CaregiverSignUpForm from '../../../components/caregiver-signup';
import SourceEmitter from '../../../lib/emitter';
import yearlyImage from '../../../images/caregiver-signup-thumbnail.png'


const metaTags = {
  description: 'Sign up to receive more information from SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Sign up | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

let Subscription_Form_Submit = null;


class RequestPage extends Component {

  constructor() {
    super()
    this.state = {
      formCompleted: false,
    };
  }

  componentDidMount() {
    Subscription_Form_Submit = SourceEmitter.addListener(
      'FormSubmitted',
      data => {
        if (data) {
          this.setState({ formCompleted: true });
        }
      }
    );
  }

  componentWillUnmount() {
    Subscription_Form_Submit && Subscription_Form_Submit.remove();
  }

  render() {
    const { formCompleted } = this.state;
    return (

      <Layout meta={metaTags}>
        <Row between="xs">
          <Col xs={12}>

          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {!formCompleted &&
              <h1>Order Your Caregiver's Kit</h1>
            }
            {!formCompleted &&
              <p>Sign up now to receive an all-in-one caregiver's kit by mail that includes resources about central precocious puberty and SUPPRELIN<sup>&reg;</sup> LA to help you and your child throughout the treatment journey. <br />
              <img src={yearlyImage } alt="Caregiver's Guide" width="50%"/><br />
                Parent/Caregiver: Please enter your information below (not your child’s).<br /><br />
                All fields are required.</p>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            {!formCompleted && <CaregiverSignUpForm />}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {formCompleted && <h4 style={{ fontWeight: 'normal', paddingTop: '2rem' }}><strong>Thank You.</strong> <br /><br />THANK YOU FOR YOUR INTEREST IN <nobr>SUPPRELIN<sup>&reg;</sup> LA.</nobr></h4>}

            {!formCompleted &&
              <p>Endo Pharmaceuticals Inc. understands that your privacy is important. By providing your name, address, and other requested information, you are giving Endo Pharmaceuticals Inc. and other parties working with us permission to communicate with you about SUPPRELIN<sup>&reg;</sup> LA or other products, services, and offers from Endo Pharmaceuticals Inc. We will not sell your name or other personal information to any party for its marketing use. To view the privacy policy, please visit <a href='https://www.endo.com/privacy-legal' target="_blank">www.endo.com/privacy-legal</a>.</p>

            }
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default RequestPage