import React, { Component } from 'react';
import { Button, FormGroup, InputGroup, ButtonGroup, Checkbox, RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment'
import axios from 'axios';
import SourceEmitter from '../../lib/emitter';
import _STATES_ from './states'
import './form.scss'
import { TH_LIST } from '@blueprintjs/icons/lib/esm/generated/iconContents';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'


const RECAPTCHA_SITE_KEY = "6LcsqPIjAAAAAPg7jo7PuJU28PnadTAnQ9WWh5x6";

const specialties = [
  "Pediatric Endocrinologist",
  "Pediatric Endocrinology Nurse",
  "Other"
]

class CaregiverSignUpForm extends Component {

  constructor(props) {
    super()
    this.state = {
      emailValue: '',
      firstNameValue: '',
      lastNameValue: '',
      streetValue: '',
      cityValue: '',
      stateValue: '',
      zipValue: '',
      preferredLanguageValue: '',
      how_did_you_hearValue: '',
      supprelinSubscribeChecked: false,
      certifiedChecked: false,
      endoSubscribeChecked: false,
      sendersFullNameValue: '',
      sendersEmailValue: '',
      FormError: false,
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      streetError: false,
      cityError: false,
      stateError: false,
      zipError: false,
      phoneError: false,
      supprelinSubscribeError: false,
      certifiedError: false,
      endoSubscribeError: false,
      sendersFullName_Error: false,
      sendersemailError: false,
      checkingReCaptchaForSubmit: false,
      isSubmitting: false,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSendersEmail = this.handleSendersEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.validateCheckBox = this.validateCheckBox.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidMount() {
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
      console.log('recpatcha loaded');
    });
  }

  componentWillUnmount() {
    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  }

  handleEmail = val => {
    this.setState({
      emailValue: val.target.value
    });

  };

  handleTextChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSendersEmail = val => {
    this.setState({
      sendersEmailValue: val.target.value
    });
  };

  validateEmail = _email => {
    const emailREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailREGEX.test(_email);
  };

  validateTextField = (_val, errorFieldName) => {

    if (!_val.length > 0) {
      this.setState({
        FormError: true,
        [errorFieldName]: true,
      });
    } else {
      this.setState({
        [errorFieldName]: false,
      });
    }

    return (_val.length > 0)
  }

  // TODO: add validation to check for true, n set error state
  validateCheckBox = (_val, errorFieldName) => {
    console.log("validdatecheckbox: " + _val);
    if (_val) {
      this.setState({
        [errorFieldName]: false,
      });
    } else {
      this.setState({
        FormError: true,
        [errorFieldName]: true,
      });
    }

    return _val
  }

  validateRadioButtons = (_val, errorFieldName) => {
    console.log("validdate captcha: " + _val);
    if (_val) {
      this.setState({
        [errorFieldName]: false,
      });
    } else {
      this.setState({
        FormError: true,
        [errorFieldName]: true,
      });
    }

    return _val
  }

  validForm = () => {
    const validEmail = this.validateEmail(this.state.emailValue);
    const validfirstName = this.validateTextField(this.state.firstNameValue, 'firstNameError');
    const validLastName = this.validateTextField(this.state.lastNameValue, 'lastNameError');
    const validAddress = this.validateTextField(this.state.streetValue, 'streetError');
    const validCity = this.validateTextField(this.state.cityValue, 'cityError')
    const validState = this.validateTextField(this.state.stateValue, 'stateError');
    const validZip = this.validateTextField(this.state.zipValue, 'zipError');
    const validAge = this.validateCheckBox(this.state.certifiedChecked, 'certifiedError');
    const validLanguage = this.validateRadioButtons(this.state.preferredLanguageValue, 'preferredLanguageError');

    console.log("valid age is " + validAge);

    // is email valid
    if (!validEmail) {
      console.log('email invalid')
      this.setState({
        FormError: true,
        emailError: true,
      });
      return false
    }

    if (!validfirstName || !validLastName || !validAddress || !validCity || !validState || !validZip || !validAge || !validLanguage) {
      console.log('form invalid')
      return false
    }

    return true;
  }

  handleStateChange = (event) => {
    const _val = event.target.value;
    this.setState({ stateValue: _val });
  }

  handleHowDidYouHearChange = (event) => {
    const _val = event.target.value;
    this.setState({ how_did_you_hearValue: _val });
  }
  handleCheckbox = (event) => {
    const _checked = event.target.checked;
    const _val = event.target.value;
    console.log(_checked + " : " + _val);
    this.setState({ [_val]: _checked });
  }
  handleCancel() {
    console.log('cancel close')
    SourceEmitter.emit(`FormCanceled`, true);
  }

  handleSubmit = () => {
    const validEmail = this.validateEmail(this.state.emailValue)
    if (this.validForm()) {
      this.setState({
        FormError: false,
        isSubmitting: true,
      });

      const dataToSend = {
        Email: this.state.emailValue,
        FirstName: this.state.firstNameValue,
        LastName: this.state.lastNameValue,
        Address1: this.state.streetValue,
        Address2: "",
        City: this.state.cityValue,
        State: this.state.stateValue,
        ZipCode: this.state.zipValue,
        Over18: (this.state.certifiedChecked) ? "Y" : "N",
        Q3: this.state.preferredLanguageValue,
        BrandOptin: 'Y',
        CorporateOptin: (this.state.endoSubscribeChecked) ? "Y" : "N",
        CampaignId: null,
        TacticId: null,
        JobId: null,
        SubscriberId: null,
        RecaptchaToken: this.state.recaptchaToken || null,
      }

      console.log(dataToSend)

      const registrationEndpoint = process.env.SUP_SFMC_API_ENDPOINT;

      axios
        .post(registrationEndpoint, dataToSend,
          {
            headers: {
              AuthKey: process.env.SUP_SFMC_HEADER_VALUE,
            }
          })
        .then(response => {
          console.log(response);
          //Clear statea
          this.setState(
            {
              emailValue: '',
              firstNameValue: '',
              lastNameValue: '',
              streetValue: '',
              cityValue: '',
              stateValue: '',
              zipValue: '',
              interested_becauseChecked1: false,
              interested_becauseChecked2: false,
              interested_becauseChecked3: false,
              interested_becauseChecked4: false,
              how_did_you_hearValue: '',
              supprelinSubscribeChecked: false,
              certifiedChecked: false,
              endoSubscribeChecked: false,
              sendersFullNameValue: '',
              sendersEmailValue: '',
              FormError: false,
              emailError: false,
              firstNameError: false,
              lastNameError: false,
              streetError: false,
              cityError: false,
              stateError: false,
              zipError: false,
              phoneError: false,
              preferredLanguageError: false,
              supprelinSubscribeError: false,
              certifiedError: false,
              endoSubscribeError: false,
              sendersFullName_Error: false,
              sendersemailError: false,
              captchaError: false,
              isSubmitting: false,
              ReCaptchaToken: '',
            }
          );
          SourceEmitter.emit(`FormSubmitted`, true);
          const isFbq = typeof window.fbq !== 'undefined'
          if (isFbq) {
            window.fbq('track', 'CompleteRegistration');
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      alert('Error: Form fields are invalid.');
    }
  };


  handleSubmitRecaptchaCheck = () => {
    this.setState({ checkingReCaptchaForSubmit: true })
    this.updateRecaptchaToken();
  }

  verifyRecaptchaCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token");

    if (this.state.checkingReCaptchaForSubmit) {
      this.state.checkingReCaptchaForSubmit = false;
      this.handleSubmit();
    }
  }

  updateRecaptchaToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  render() {

    const { emailValue, firstNameValue, lastNameValue, streetValue, cityValue, stateValue, zipValue, cityError, stateError, zipError, emailError, firstNameError, lastNameError, FormError, streetError, phoneError, certifiedError, preferredLanguageError, isSubmitting } = this.state;
    const { email } = this.props;

    return (

      <div className='request-a-rep-form-container'>
        <FormGroup
          helperText="Please add your first name"
          label="First Name"
          labelFor="text-firstName"
          labelInfo=""
        >
          <InputGroup id="text-firstName" intent={(firstNameError && 'danger') || 'primary'} placeholder="Jon" large name="firstNameValue" onChange={this.handleTextChange} value={firstNameValue} />
        </FormGroup>
        <FormGroup
          helperText="Please add your last name"
          label="Last Name"
          labelFor="text-lastName"
          labelInfo=""
        >
          <InputGroup id="text-lastName" intent={(lastNameError && 'danger') || 'primary'} placeholder="Smith" large name="lastNameValue" onChange={this.handleTextChange} value={lastNameValue} />
        </FormGroup>
        <FormGroup
          helperText="Please add your street address"
          label="Street Address"
          labelFor="text-streetAddress"
          labelInfo=""
        >
          <InputGroup id="text-streetAddress" intent={(streetError && 'danger') || 'primary'} placeholder="123 Main St" large name="streetValue" onChange={this.handleTextChange} value={streetValue} />
        </FormGroup>
        <FormGroup
          helperText="Please add your city"
          label="City"
          labelFor="text-city"
          labelInfo=""
        >
          <InputGroup id="text-city" intent={(cityError && 'danger') || 'primary'} placeholder="Malvern" large name="cityValue" onChange={this.handleTextChange} value={cityValue} />
        </FormGroup>
        <FormGroup
          helperText="Please select your state"
          label="State"
          labelFor="text-practiceName"
          labelInfo=""
          intent={(stateError && 'danger') || ''}
        >
          <div className="bp3-select bp3-large bp3-fill">
            <select onChange={this.handleStateChange} className="select-report">
              <option defaultValue value="">Choose a State</option>
              {
                _STATES_.map(item => {
                  return <option value={item['alpha-2']} key={item['alpha-2']}>{item.name}</option>
                })
              }
            </select>
          </div>
        </FormGroup>
        <FormGroup
          helperText="Please add your ZIP Code"
          label="ZIP"
          labelFor="text-zip"
          labelInfo=""
        >
          <InputGroup id="text-zip" intent={(cityError && 'danger') || 'primary'} placeholder="19355" large name="zipValue" onChange={this.handleTextChange} value={zipValue} inputMode="numeric" pattern="[0-9]*" />
        </FormGroup>
        <FormGroup label="Preferred Language:">
          <RadioGroup
            className={(preferredLanguageError && 'danger')}
            name="preferredLanguageValue"
            selectedValue={this.state.preferredLanguageValue}
            onChange={this.handleTextChange}
            inline
          >
            <Radio
              value="English"
            />English<br />
            <Radio
              value="Spanish"
            />Spanish
          </RadioGroup>
        </FormGroup>

        <FormGroup
          helperText="Please add your email address"
          label="Email Address"
          labelFor="text-email"
          labelInfo=""
        >
          <InputGroup id="text-email" intent={(emailError && 'danger') || 'primary'} placeholder="jon.smith@example.com" large name="emailValue" onChange={this.handleTextChange} value={emailValue} />
        </FormGroup>
        <p><strong>Yes</strong>, I would like to receive information in the future about <nobr>SUPPRELIN<sup>&reg;</sup> LA</nobr> and related health information.</p>
        <FormGroup>
          <Checkbox value="certifiedChecked" className={(certifiedError && 'danger')} onChange={this.handleCheckbox}>I certify that I am 18 years of age or older.</Checkbox>
          <Checkbox value="endoSubscribeChecked" onChange={this.handleCheckbox}><strong>Yes</strong>, I would also like to receive information in the future about Endo products, programs, and services that may be of interest to me.</Checkbox>
        </FormGroup>

        <ButtonGroup style={{ minWidth: 200 }}>
          <Button intent="primary" type='submit' large text="Submit" onClick={this.handleSubmitRecaptchaCheck} disabled={isSubmitting} />
        </ButtonGroup>
        <ReCaptcha
          ref={ref => this.recaptcha = ref}
          sitekey={RECAPTCHA_SITE_KEY}
          verifyCallback={this.verifyRecaptchaCallback}
        />
      </div>
    );
  }
};

export default CaregiverSignUpForm;
